a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.m-pane--right {
  align-items: start;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  right: 0;
  position: fixed!important;
  top: 0;
  transform: translate(100%);
  transition: transform .2s ease-in-out;
}

.m-overlay__pane {
  position: fixed!important;
}

.m-pane {
  width: 40rem;
}

.modal-position {
  position: fixed;
  top: 40%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}