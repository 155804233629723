@charset 'utf-8';

/* Core branding API */
@import '~@a-ui/core/dist/assets/styles/quarks';

body {
  display: flex;
  min-height: 100vh;
}

#root {
  display: flex;
  width: 100%;
}
